<template>
    <v-container class="pa-0">
      <v-row dense>
        <!-- Recipients -->
        <v-col cols="12">
          <v-combobox
            v-model="iRecipients"
            :items="iRecipientItems"
            multiple
            persistent-hint
            small-chips
            deletable-chips
            label="Recipients"
            hint="Enter the email addresses to send to"
            :disabled="loading"
          >
            <template slot="no-data">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    Press <kbd>enter</kbd> to add this email
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-combobox>
        </v-col>
        <!-- Subject -->
        <v-col cols="12">
          <v-text-field
            label="Subject*"
            name="subject"
            v-model="iSparkpost.subject"
            autocomplete="off"
            type="search"
            :disabled="loading"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row dense >
        <v-col cols="6" class="body-2 pa-0">
          Body:
        </v-col>
        <v-col cols="6" class="pa-0 text-right">
          <v-btn
            color="color3"
            text
            @click.stop="preview = !preview"
            class="xsmall ma-0"
          >{{preview ? 'Edit' : 'Preview'}}</v-btn>
        </v-col>
      </v-row>
      <v-row dense>
        <!-- Message -->
        <v-col cols="12">
          <div style="border: 1px solid grey" class="pa-2" v-html="iSparkpost.paragraph[0].text" v-if="preview"></div>
          <v-textarea
            v-else
            v-model="iSparkpost.paragraph[0].text"
            auto-grow
          ></v-textarea>
        </v-col>
      </v-row>
    </v-container>

</template>

<script>
import Sparkpost from '@/classes/Sparkpost'

export default {
  props: ['generic', 'recipients', 'recipientItems', 'sparkpost', 'active', 'tournamentId', 'bidId'],
  data () {
    return {
      loading: false,
      iRecipients: [],
      iSparkpost: new Sparkpost(),
      preview: true
    }
  },
  computed: {
    subject () {
      return this.iSparkpost.subject
    },
    body () {
      return this.iSparkpost.paragraph[0].text
    },
    valid () {
      return !!(this.iRecipients.length > 0 && this.body && this.subject)
    },
    iRecipientItems () {
      return this.recipientItems || []
    },
    dto () {
      return {
        tournamentId: this.tournamentId,
        bidId: this.bidId,
        recipients: this.iRecipients,
        sparkpost: this.iSparkpost
      }
    }
  },
  methods: {
    init () {
      if (this.recipientItems) this.iRecipients = [...this.recipientItems]
      if (this.sparkpost) this.iSparkpost = new Sparkpost(this.sparkpost)
      this.preview = !!this.body
    },
    send () {
      if (!this.valid) {
        this.$emit('email-error', 'Oops internal error')
        return
      }
      this.preview = true
      this.loading = true
      this.$VBL.post.notification(this.dto)
        .then(r => {
          this.sent = true
          this.$emit('email-sent')
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    }
  },
  watch: {
    active: function (val) {
      if (val) this.init()
    },
    valid: function (val) {
      this.$emit('valid-change', val)
    },
    loading: function (val) {
      this.$emit('loading-change', val)
    }
  },
  components: {
  },
  mounted () {
    this.init()
  }
}
</script>

<style>

</style>

<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="500px"
    transition="dialog-transition"
    :persistent="loading"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        color="color2 color2Text--text"
        text
        small
      >
        <v-icon>fas fa-cog</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar color="color2 color2Text--text">
        <v-toolbar-title>Bid Program Settings</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="color2Text"
          text
          icon
          small
          @click="dialog = false"
          :disabled="loading"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-container class="pa-0">
          <v-row dense>
            <v-col cols="12">
              <v-switch :label="requiredLabel" v-model="internalProgram.required" color="success"></v-switch>
            </v-col>
            <v-col cols="12">
              <v-select
                :items="subPolicies"
                v-model="internalProgram.subPolicy"
                label="Sub Policy"
                :hint="selectedSubPolicy ? selectedSubPolicy.hint : ''"
                persistent-hint
                color="color3"
                item-color="color3"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-btn
                color="color3"
                text
                class="xsmall mx-0 px-0"
                @click.stop="toggleDiscount">{{this.showDiscount ? 'Remove Discount' : 'Add Discount'}}</v-btn>
            </v-col>
          </v-row>
          <v-expand-transition>
            <div v-if="showDiscount">
              <v-row dense>
                <v-col cols="6">
                  <v-text-field
                    label="Discount"
                    v-model="discountValue"
                    type="number"
                    color="color3"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-select
                    :items="['Dollars', 'Percent']"
                    v-model="discountType"
                    label="Type"
                    color="color3"
                    item-color="color3"
                  ></v-select>
                </v-col>
              </v-row>
            </div>
          </v-expand-transition>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red white--text"
          fab
          small
          @click.stop="dialog = false"
          :disabled="loading"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
        <v-btn
          color="color3 color3Text--text"
          fab
          small
          :disabled="!dirty"
          :loading="loading"
          @click.stop="save"
        >
          <v-icon>fas fa-save</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BidSubPolicies from '@/classes/BidSubPolicies'

export default {
  props: ['program'],
  data () {
    return {
      dialog: false,
      internalProgram: {},
      addDiscount: false,
      discountValue: 0,
      discountType: 'Dollars',
      loading: false
    }
  },
  computed: {
    subPolicies () {
      return BidSubPolicies
    },
    selectedSubPolicy () {
      return this.internalProgram && this.subPolicies.find(f => f.value === this.internalProgram.subPolicy)
    },
    requiredLabel () {
      return `Bid is ${this.internalProgram.required ? '' : 'not '}required for registration`
    },
    hasDiscount () {
      return this.internalProgram.discountAmount || this.internalProgram.discountPercentage
    },
    showDiscount () {
      return this.addDiscount || this.internalProgram.discountAmount || this.internalProgram.discountPercentage
    },
    dirty () {
      return JSON.stringify(this.program) !== JSON.stringify(this.internalProgram)
    }
  },
  methods: {
    toggleDiscount () {
      if (this.showDiscount) {
        this.internalProgram.discountAmount = 0
        this.internalProgram.discountPercentage = 0
        this.addDiscount = false
      } else {
        this.setDiscount()
        this.addDiscount = true
      }
    },
    setDiscount () {
      if (this.discountType === 'Dollars') {
        this.internalProgram.discountAmount = +this.discountValue
        this.internalProgram.discountPercentage = 0
      } else {
        this.internalProgram.discountAmount = 0
        this.internalProgram.discountPercentage = +this.discountValue
      }
    },
    save () {
      if (!this.dirty) return
      this.loading = true
      this.$VBL.post.bidProgram(this.internalProgram)
        .catch(e => console.log(e.response))
        .finally(() => {
          this.loading = false
          this.dialog = false
        })
    }
  },
  watch: {
    dialog: function (val) {
      if (val) {
        this.internalProgram = Object.assign({}, this.program)
      } else {
        this.internalProgram = {}
      }
    },
    discountValue: 'setDiscount',
    discountType: 'setDiscount'
  }
}
</script>

<style scoped>
.v-icon {
  transition: 0ms
}
</style>

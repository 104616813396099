export default class SparkpostParagraph {
  text = null
  isHtml = true
  align = 'Left'

  constructor (dto) {
    if (dto) {
      Object.assign(this, dto)
    }
  }

  get valid () {
    return !!this.text
  }
}

<template>
  <v-card v-if="myBid">
    <v-toolbar color="color2 color2Text--text">
      <v-toolbar-title>Bid Details</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        color="color2Text"
        text
        icon
        @click.stop="$emit('cancel-click')"
      >
        <v-icon>fas fa-times</v-icon>
      </v-btn>
    </v-toolbar>
    <loading-bar :value="loading"></loading-bar>
    <v-card-text class="pt-0">
      <v-container class="pt-0">
        <v-row dense>
          <v-col cols="12" class="caption">
            {{caption | pipeArray}}
          </v-col>
        </v-row>
        <v-window v-model="window" touchless>
          <!-- DETAILS WINDOW -->
          <v-window-item :value="0">
            <v-row dense>
              <!-- BID ID -->
              <v-col cols="6">
                <v-text-field
                  label="Bid Id"
                  v-model="myBid.bidId"
                  hint="The unique code that is given to the bid recipient - leave blank to auto-assign"
                  persistent-hint
                  :disabled="!editing"
                  color="color3"
                ></v-text-field>
              </v-col>
              <!-- SUB POLICY -->
              <v-col cols="6">
                <v-select
                  :items="subPolicies"
                  v-model="myBid.subPolicy"
                  label="Sub Policy"
                  :hint="selectedSubPolicy ? selectedSubPolicy.hint : ''"
                  persistent-hint
                  :disabled="!editing"
                  color="color3"
                  item-color="color3"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Earned At"
                  v-model="myBid.earnedAt"
                  hint="The event the bid was earned at"
                  persistent-hint
                  :disabled="!editing"
                  color="color3"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Earned For"
                  v-model="myBid.earnedFor"
                  hint="i.e. 18U - 1st"
                  persistent-hint
                  :disabled="!editing"
                  color="color3"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <date-time-picker
                  label="Earned On"
                  :datetime.sync="myBid._dtEarned"
                  :hint="myBid.earnedAt ? `When was ${myBid.earnedAt}` : ''"
                  persistent-hint
                  :disabled="!editing"
                  type="date"
                ></date-time-picker>
              </v-col>
              <!-- DIVISIONS -->
              <v-col cols="12" v-if="tournament.id === 287">
                <v-select
                  :items="tournament.publicDivisions"
                  v-model="myBid.tournamentDivisionList"
                  item-value="id"
                  item-text="name"
                  label="Divisions"
                  chips multiple
                  :disabled="!editing"
                  color="color3"
                  item-color="color3"
                ></v-select>
              </v-col>
              <!-- DIVISIONS -->
              <v-col cols="12" v-else>
                <v-select
                  :items="options.divisions"
                  v-model="myBid.divisionList"
                  item-value="id"
                  item-text="name"
                  label="Divisions"
                  chips multiple
                  :disabled="!editing"
                  color="color3"
                  item-color="color3"
                ></v-select>
              </v-col>
              <!-- GENDER -->
              <v-col cols="6" v-if="options.gender.length > 1 && tournament.id !== 287">
                <v-select
                  :items="options.gender"
                  v-model="myBid.genderId"
                  label="Gender"
                  item-value="id"
                  item-text="name"
                  :hint="myBid.genderId ? 'This bid can only be used for the selected gender' : 'To restrict the bid to specific gender select it here'"
                  persistent-hint
                  :disabled="!editing"
                  color="color3"
                  item-color="color3"
                ></v-select>
              </v-col>
            </v-row>
            <!-- PLAYERS TOOLBAR -->
            <v-toolbar dense flat :color="editing ? 'color1 color1Text--text' : 'grey lighten-3'">
              <v-toolbar-title>Players</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-fab-transition>
                <v-btn
                  v-if="editing"
                  color="color3 color3Text--text"
                  fab small
                  @click.stop="showSearch = true"
                >
                  <v-icon>fas fa-plus</v-icon>
                </v-btn>
              </v-fab-transition>
            </v-toolbar>
            <v-row dense>
              <!-- PLAYERS SEARCH -->
              <v-expand-transition>
                <v-col cols="12" v-if="editing && showSearch">
                  <player-lookup @player-selected="addPlayer" :clearOnSelect="true" ref="search"></player-lookup>
                </v-col>
              </v-expand-transition>
              <!-- PLAYERS -->
              <v-col cols="12">
                <v-list class="py-0" :disabled="!editing">
                  <template v-for="(player) in myBid.players">
                    <v-list-item :key="`p-${player.id}`">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{player.fullName}} {{player.ageGroup | ageDivisionYouth}}
                          <span class="grey--text text--lighten-1 caption">
                            {{player.email ? ` - ${player.email}` : ''}}
                          </span>
                        </v-list-item-title>
                        <v-list-item-subtitle>{{player.cityState}}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-fab-transition>
                          <v-btn
                            v-if="editing"
                            color="white error--text"
                            fab small
                            @click.stop="dropPlayer(player)"
                          >
                            <v-icon>fas fa-trash</v-icon>
                          </v-btn>

                        </v-fab-transition>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider :key="`d-${player.id}`"></v-divider>
                  </template>
                </v-list>
              </v-col>
              <!-- DISCOUNT SWITCH -->
              <v-col cols="12">
                <v-switch
                  label="Discount"
                  v-model="showDiscount"
                  :disabled="!editing"
                  color="color3"
                ></v-switch>
              </v-col>
            </v-row>
            <!-- DISCOUNT -->
            <v-expand-transition>
              <div v-if="showDiscount">
                <v-row dense>
                  <v-col cols="6">
                    <v-text-field
                      label="Discount"
                      v-model="discountValue"
                      type="number"
                      :disabled="!editing"
                      color="color3"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      :items="['Dollars', 'Percent']"
                      v-model="discountType"
                      label="Type"
                      :disabled="!editing"
                      color="color3"
                      item-color="color3"
                    ></v-select>
                  </v-col>
                </v-row>
              </div>
            </v-expand-transition>
            <v-alert type="warning" v-if="duplicate" :value="true" transition="expand-transition">
              This team already has a bid ({{duplicate.bidId}})
            </v-alert>
          </v-window-item>
          <!-- NOTES WINDOW -->
          <v-window-item :value="1">
            <v-row dense>
              <v-col cols="12">
                <v-textarea
                  v-model="myBid.notes"
                  label="Notes"
                  :disabled="!editing"
                  auto-grow
                ></v-textarea>
              </v-col>
            </v-row>
          </v-window-item>
          <!-- DELETE WINDOW -->
          <v-window-item :value="2">
            <v-row dense>
              <v-col cols="12" class="title text-center pt-12">
                Are you sure?
              </v-col>
              <v-col cols="12" class="subheading text-center">
                Deleting can not be undone.
              </v-col>
              <v-col cols="12" class="text-center">
                <v-btn color="success white--text" @click.stop="onDeleteClick" :loading="saving" class="mr-2">Yes</v-btn>
                <v-btn color="error white--text" @click.stop="window = 0" :disabled="saving">No</v-btn>
              </v-col>
            </v-row>
          </v-window-item>
          <!-- EMAIL WINDOW -->
          <v-window-item :value="3">
              <v-expand-transition>
                <div v-if="!compose">
                  <v-row dense>
                    <v-col cols="12" class="title">
                      {{emailString}}
                    </v-col>
                    <v-col class="text-center" cols="12" >
                      <v-btn color="color3" text @click.stop="compose = true">{{this.bid.emailCount ? 're-send email' : 'Send email'}}</v-btn>
                    </v-col>
                  </v-row>
                </div>
              </v-expand-transition>
              <v-expand-transition>
                <div v-if="compose">
                  <v-row dense>
                    <v-col cols="12">
                      <bid-emailer
                        :bid="myBid"
                        :program="program"
                        :active="window === 3 && compose"
                        ref="emailer"
                        @email-sent="onEmailSent"
                        @email-error="onEmailError"
                        @valid-change="onValidChange"
                        @loading-change="onLoadingChange"
                      ></bid-emailer>
                    </v-col>
                  </v-row>
                </div>
              </v-expand-transition>
          </v-window-item>
        </v-window>
        <v-row dense>
          <v-col cols="12" class="success--text title">
            <v-alert type="success" :value="snack" transition="scale-transition" dismissible>
              Save Complete!
            </v-alert>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <!-- BUTTONS -->
    <v-card-actions>
      <v-fab-transition>
        <v-btn
          :color="leftFab.color"
          small
          fab
          @click.stop="leftFab.onClick"
          :disabled="saving"
        >
          <v-icon>{{leftFab.icon}}</v-icon>
        </v-btn>
      </v-fab-transition>
      <v-fab-transition>
        <v-btn
          v-if="window === 0"
          color="color3Text color3--text"
          small
          fab
          @click.stop="compose = false; window = 3"
          :disabled="saving"
        >
          <v-icon>fas fa-envelope</v-icon>
        </v-btn>
      </v-fab-transition>
      <v-spacer></v-spacer>
      <!-- DELETE BUTTON -->
        <v-fab-transition>
          <v-btn
            color="white error--text"
            key="save"
            v-if="editing && window !== 2 && bid.id"
            fab
            small
            @click.stop="window = 2"
            :loading="saving"
            class="mr-1"
          >
            <v-icon small>fas fa-trash</v-icon>
          </v-btn>
        </v-fab-transition>
      <!-- SAVE BUTTON -->
        <v-fab-transition>
          <v-btn
            color="color3 color3Text--text"
            key="save"
            v-if="editing && window < 2"
            :disabled="!dirty"
            fab
            small
            @click.stop="onSaveClick"
            :loading="saving"
            class="mr-1"
          >
            <v-icon>fas fa-save</v-icon>
          </v-btn>
        </v-fab-transition>
        <v-fab-transition>
          <v-btn
            v-if="activeFab"
            :color="activeFab.color"
            :key="activeFab.icon"
            fab
            small
            @click.stop="activeFab.onClick"
            :disabled="saving || activeFab.disabled"
            :loading="activeFab.loading"
          >
            <v-icon small>{{activeFab.icon}}</v-icon>
          </v-btn>
        </v-fab-transition>
    </v-card-actions>
  </v-card>
</template>

<script>
import Bid from '@/classes/Bid'
import BidSubPolicies from '@/classes/BidSubPolicies'
import PlayerLookup from '@/components/Player/Search/PlayerLookup'
import BidEmailer from '@/components/Tournament/Bids/BidEmailer.vue'
import DateTimePicker from '@/components/Utils/DateTimePicker.vue'
import { mapGetters } from 'vuex'

export default {
  props: ['bid', 'active', 'options', 'teamIds', 'bids', 'program'],
  data () {
    return {
      editing: false,
      addDiscount: false,
      iBid: {},
      fab: null,
      saving: false,
      iDiscountValue: null,
      iDiscountType: null,
      showSearch: false,
      window: 0,
      snack: false,
      loading: false,
      compose: false,
      emailValid: false,
      emailLoading: false
    }
  },
  computed: {
    ...mapGetters(['tournament']),
    emailString () {
      return this.bid.emailCount ? this.bid.emailCount === 1 ? 'This bid has been emailed' : `This bid has been emailed ${this.bid.emailCount} times` : 'This bid has not been emailed yet'
    },
    duplicate () {
      return this.myBid && this.bids && this.bids.find(f => f.teamId === this.myBid.teamId && f.id !== this.myBid.id)
    },
    caption () {
      if (this.bid) {
        var a = []
        this.bid.id && a.push(`Id: ${this.bid.id}`)
        this.bid.dtCreated && this.bid.createdBy && a.push(`Created: ${this.bid.dtCreated.format('LLLL')} By: ${this.bid.createdBy}`)
        a.push(`ProgramId: ${this.bid.bidProgramId}`, `TournamentId: ${this.bid.tournamentId}`)
        return a
      }
      return []
    },
    leftFab () {
      return this.window === 0 ? {
        icon: 'fas fa-sticky-note',
        color: 'color3Text color3--text',
        onClick: () => { this.window = 1 }
      } : {
        icon: 'fas fa-caret-left',
        color: 'color3Text color3--text',
        onClick: () => { this.window = 0 }
      }
    },
    myBid () {
      return this.editing ? this.iBid : this.bid
    },
    divisions () {
      if (!this.myBid) return null
      var divs = this.options.divisions.filter(f => this.myBid.divisionList.includes(f.id))

      return divs.length ? divs.map(m => m.name).join(', ') : 'Any'
    },
    subPolicies () {
      return BidSubPolicies
    },
    selectedSubPolicy () {
      return this.subPolicies.find(f => f.value === this.bid.subPolicy)
    },
    discountValue: {
      get: function () {
        return this.myBid.discountValue
      },
      set: function (val) {
        this.iDiscountValue = val
        this.setDiscount()
      }
    },
    discountType: {
      get: function () {
        return this.myBid.discountType
      },
      set: function (val) {
        this.iDiscountType = val
        this.setDiscount()
      }
    },
    showDiscount: {
      get: function () {
        return this.addDiscount || this.myBid.discountAmount || this.myBid.discountPercentage
      },
      set (val) {
        this.addDiscount = val
        if (!val) {
          this.iBid.discountAmount = 0
          this.iBid.discountPercentage = 0
        } else {
          this.setDiscount()
        }
      }
    },
    activeFab () {
      return this.window === 3 ? {
        color: 'color3 color3Text--text',
        icon: 'fas fa-paper-plane',
        onClick: this.onSendClick,
        tooltip: 'Send',
        disabled: !this.compose || !this.emailValid,
        loading: this.emailLoading
      } : !this.editing ? {
        color: 'color3Text color3--text',
        icon: 'fas fa-pencil-alt',
        onClick: this.edit,
        tooltip: 'Edit',
        disabled: false,
        loading: false
      } : {
        color: 'red white--text',
        icon: 'fas fa-times',
        onClick: this.cancel,
        tooltip: 'Cancel',
        disabled: false,
        loading: false
      }
    },
    dirty () {
      return JSON.stringify(this.bid.dto) !== JSON.stringify(this.iBid.dto)
    },
    dto () {
      return this.iBid.dto
    }
  },
  methods: {
    addPlayer (player) {
      if (this.myBid) {
        this.myBid.players.push(player)
        this.showSearch = false
      }
    },
    dropPlayer (player) {
      if (this.myBid) {
        this.myBid.players = this.myBid.players.filter(f => f.id !== player.id)
      }
    },
    edit () {
      this.iBid = new Bid(JSON.stringify(this.bid))
      this.iDiscountValue = this.iBid.discountValue
      this.iDiscountType = this.iBid.discountType
      this.addDiscount = false
      this.editing = true
    },
    cancel () {
      this.editing = false
      this.showSearch = false
      this.snack = false
      this.window = 0
      if (!this.bid.id) this.$emit('cancel-click')
    },
    setDiscount () {
      if (this.iDiscountType === 'Dollars') {
        this.iBid.discountAmount = +this.iDiscountValue
        this.iBid.discountPercentage = 0
      } else {
        this.iBid.discountAmount = 0
        this.iBid.discountPercentage = +this.iDiscountValue / 100
      }
    },
    onSaveClick () {
      if (!this.dto) return
      this.saving = true
      this.$VBL.post.bid(this.dto)
        .then(r => {
          this.bid.update(r.data)
          this.$emit('save-complete')
          this.snack = true
          setTimeout(() => {
            this.snack = false
          }, 6000)
          this.editing = false
        })
        .catch(e => { console.log(e.response) })
        .finally(() => {
          this.saving = false
        })
    },
    onDeleteClick () {
      if (!this.bid || this.bid.id === 0) {
        this.cancel()
        return
      }
      this.saving = true
      this.$VBL.delete.bid(this.bid.id)
        .then(r => {
          this.$emit('delete-complete')
          this.cancel()
        })
        .catch(e => { console.log(e.response) })
        .finally(() => {
          this.saving = false
        })
    },
    onSendClick () {
      this.$refs.emailer.send()
    },
    getEmailCount () {
      this.loading = true
      this.$VBL.get.bidEmailCount(this.bid.id)
        .then(r => {
          this.bid.emailCount = r.data
        })
        .catch(e => { console.log(e.response) })
        .finally(() => { this.loading = false })
    },
    onEmailSent () {
      this.getEmailCount()
      this.compose = false
      this.emailLoading = false
    },
    onEmailError (val) {
      this.emailError = val
      this.compose = false
    },
    onValidChange (val) {
      this.emailValid = val
    },
    onLoadingChange (val) {
      this.emailLoading = val
    }
  },
  watch: {
    active: function (val) {
      this.getEmailCount()
      if (!val) {
        this.cancel()
      } else if (!this.bid.id) {
        this.edit()
      }
      this.window = 0
    },
    editing: function (val) {
      this.$emit('editing-change', val)
    },
    compose: function (val) {
      this.$emit('editing-change', val)
    },
    showSearch: function (val) {
      if (val) {
        setTimeout(() => {
          this.$refs.search.$el.focus()
        }, 500)
      }
    },
    window: function (val) {
      this.compose = false
      if (val > 2) this.editing = false
    }
  },
  mounted () {
    this.getEmailCount()
    this.bid.id && this.edit()
  },
  components: {
    PlayerLookup,
    BidEmailer,
    DateTimePicker
  }
}
</script>

<style>

</style>

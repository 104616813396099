<template>
  <email-composer
    :generic="true"
    :recipientItems="bidPlayers"
    :sparkpost="emailTemplate"
    :active="active"
    ref="emailer"
    :tournamentId="bid.tournamentId"
    :bidId="bid.id"
    @email-sent="onEmailSent"
    @email-error="onEmailError"
    @valid-change="onValidChange"
    @loading-change="onLoadingChange"
  >
  </email-composer>
</template>

<script>
import EmailComposer from '@/components/Notifications/EmailComposer.vue'
import { formatArray } from '@/Filters'
import { mapGetters } from 'vuex'

export default {
  props: ['bid', 'program', 'active'],
  computed: {
    ...mapGetters(['tournament']),
    bidPlayers () {
      return this.bid && this.bid.players ? this.bid.players.map(m => m.email) : []
    },
    bidPlayerNames () {
      return this.bid && this.bid.players ? this.bid.players.map(m => m.fullName) : []
    },
    emailTemplate () {
      return this.program && this.program.emailTemplate ? this.program.emailTemplate
        .replace(/{{bidId}}/g, this.bid.bidId)
        .replace(/{{earnedAt}}/g, this.bid.earnedAt)
        .replace(/{{earnedOn}}/g, this.bid.dtEarned?.format('L'))
        .replace(/{{goldSilver}}/g, this.goldSilver)
        .replace(/{{fullName}}/g, formatArray(this.bidPlayerNames)) : null
    },
    goldSilver () {
      if (!this.bid || !this.bid.tournamentDivisionList.length) return null
      const divisions = this.tournament.publicDivisions.filter(f => this.bid.tournamentDivisionList.includes(f.id))
      const gold = divisions.filter(f => f.name.toLowerCase().includes('gold'))
      return gold.length ? 'Gold' : 'Silver'
    }
  },
  methods: {
    send () {
      this.$refs.emailer.send()
    },
    onEmailSent (val) {
      this.$emit('email-sent', val)
    },
    onEmailError (val) {
      this.$emit('email-error', val)
    },
    onValidChange (val) {
      this.$emit('valid-change', val)
    },
    onLoadingChange (val) {
      this.$emit('loading-change', val)
    }
  },
  components: {
    EmailComposer
  }
}
</script>

<style>

</style>

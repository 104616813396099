import SparkpostParagraph from './SparkpostParagraph'
import DtoUpdate from './DtoUpdate'

export default class Sparkpost {
  skiphead = true
  subject = null
  preheader = null
  header = null
  subheader = null
  headerAlign = null
  paragraph = [new SparkpostParagraph()]
  actionText = null
  actionLink = null
  closing = null
  signature = null

  constructor (dto) {
    if (dto) {
      this.update(dto)
    }
  }

  update (dto) {
    if (typeof dto === 'string') dto = JSON.parse(dto)
    const exclude = ['paragraph']
    DtoUpdate(dto, this, exclude)

    if (dto.paragraph) {
      this.paragraph = dto.paragraph.map(m => new SparkpostParagraph(m))
    }
  }

  get valid () {
    return !!(this.subject && this.closing && this.signature && !this.paragraph.filter(f => !f.valid).length)
  }
}
